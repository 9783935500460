import { Fragment, useRef } from "react";
import { isEven } from "../../assets/shared/formatNumber";
import Table from "../UI/Table/Table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TablaProcesosFiltros from "./TablaProcesosFiltros";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import DetalleProceso from "../../containers/DetalleProceso/DetalleProceso";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "97.5vw",
    height: "100%",
    backgroundColor: "white",
    paddingTop: "5px",
  },
  iconsHeader: {
    display: "block",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    maxHeight: "30px",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const referenceColor = (urgencia, cantidad, cantidadPendiente, cerrado, atrasado) => {
  if (urgencia) return "#FEA163";
  else {
    if(atrasado){
      return "#E50802"
    }
    else
      if (cantidadPendiente === 0 || cantidad === cantidadPendiente || cerrado) return "#80C16E";
      else return "#6CBDFB";
  }
};

const clientMaterial = (material) => {
  if (material) return "Propio";
  else return "Cliente";
};

const TablaProcesos = ({
  maquinas,
  clientes,
  tipo,
  onChangeFiltros,
  procesos,
  nombre,
  filtros,
  customWidth,
  cerrado,
  onOpenDetalleDelTrabajoModal,
  trabajos
}) => {
  const headerStyle = {
    fontSize: "14px",
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: "#fafafa",
  };

  const cellStyles = {
    fontSize: "12px",
    paddingBottom: "5px",
    paddingTop: "5px",
    height: "49px",
  };

  const columns = [
    {
      title: ``,
      field: "id",
      align: "left",
      sorting: false,
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          color: cellData < 0 && "red",
          width: "1%",
        };
      },
      headerStyle: headerStyle,
      render: (rowData) => buildActionsButtons(rowData),
    },
    {
      title: ``,
      field: "",
      align: "left",
      sorting: false,
      cellStyle: (e, rowData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          backgroundColor: referenceColor(
            rowData.urgencia,
            rowData.cantidad,
            rowData.cantidadPendiente,
            cerrado,
            rowData.atrasado
          ),
          width: "1%",
        };
      },
      headerStyle: headerStyle,
      headerStyle: {
        fontSize: "14px",
        padding: "0px",
        backgroundColor: "#fafafa",
      },
    },
    {
      title: "Fec. Entrega",
      field: "fechaEntrega",
      align: "left",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          width: "150px",
        };
      },
      headerStyle: headerStyle,
      render: (rowData) =>
        `${moment(rowData.fechaEntrega).format("DD/MM/YYYY")}`,
    },
    {
      title: `OT`,
      field: "ordenDeTrabajo",
      align: "left",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          minWidth: 160,
        };
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fafafa",
      },
    },
    {
      title: `OC`,
      field: "nroOC",
      align: "left",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          minWidth: 160,
        };
      },
      headerStyle: {
        fontSize: "14px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#fafafa",
      },
    },
    {
      title: "Cliente",
      field: "cliente",
      align: "left",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          minWidth: 200,
        };
      },
      headerStyle: headerStyle,
    },
    {
      title: `Espesor`,
      field: "espesor",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
        };
      },
      headerStyle: headerStyle,
    },
    {
      title: `Calidad`,
      field: "material",
      align: "left",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
        };
      },
      headerStyle: headerStyle,
    },
    {
      title: `Material`,
      field: "propio",
      align: "left",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
        };
      },
      headerStyle: headerStyle,
    },
    {
      title: `Tiempo Corte`,
      field: "tiempoCorte",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
        };
      },
      headerStyle: headerStyle,
    },
    {
      title: `Descripción`,
      field: "descripcion",
      align: "left",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          minWidth: 250,
        };
      },
      headerStyle: headerStyle,
    },
    {
      title: `Cantidad`,
      field: "cantidad",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
        };
      },
      headerStyle: headerStyle,
    },
    {
      title: `Nº Programa`,
      field: "numeroDePrograma",
      align: "right",
      type: "numeric",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
        };
      },
      headerStyle: headerStyle,
    },
    {
      title: `Proceso`,
      field: "proceso",
      align: "left",
      cellStyle: (cellData) => {
        return {
          fontSize: "12px",
          paddingBottom: "5px",
          paddingTop: "5px",
          minWidth: 130,
        };
      },
      headerStyle: headerStyle,
    },
  ];
  const classes = useStyles();

  const data = () => {
    return trabajos.map((proceso) => {
      return {
        id: proceso.idTrabajo,
        fechaEntrega: proceso.fechaEntrega,
        ordenDeTrabajo: proceso.ordenDeTrabajo,
        espesor: proceso.espesor,
        material: proceso.material.descripcion,
        propio: clientMaterial(proceso.propio),
        descripcion: proceso.descripcion,
        cantidad: proceso.cantidad,
        numeroDePrograma: proceso.nroPrograma,
        cliente: proceso.cliente.razonSocial,
        urgencia: proceso.urgencia,
        cantidadPendiente: proceso.cantidadPendiente,
        proceso: proceso.proceso,
        idSucursal: proceso.idSucursal,
        idSistema: proceso.idSistema,
        idComproba: proceso.idComproba,
        nroReferencia: proceso.nroReferencia,
        tiempoCorte: proceso.tiempoUnitario, 
        nroOC: proceso.nroOC, 
        pausado: proceso.pausado,
        atrasado: proceso.atrasado
      };
    });
  };

  const buildActionsButtons = (rowData) => {
    return (
      <div style={{ textAlign: "right" }}>
        <Tooltip title="Ver detalle del proceso">
          <IconButton
            size="small"
            onClick={() =>
              openDetalleTrabajo(
                rowData.ordenDeTrabajo,
                rowData.idSucursal,
                rowData.idSistema,
                rowData.idComproba,
                rowData.nroReferencia,
                cerrado
              )
            }
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const openDetalleTrabajo = (
    ordenDeTrabajo,
    idSucursal,
    idSistema,
    idComproba,
    nroReferencia,
    cerrado
  ) => {
    const title = "Detalle de trabajo";
    const body = detalleDelTrabajo(
      ordenDeTrabajo,
      idSucursal,
      idSistema,
      idComproba,
      nroReferencia,
      cerrado
    );
    onOpenDetalleDelTrabajoModal(true, title, body);
  };

  const detalleDelTrabajo = (
    ordenDeTrabajo,
    idSucursal,
    idSistema,
    idComproba,
    nroReferencia,
    cerrado
  ) => {
    return (
      <DetalleProceso
        procesoId={ordenDeTrabajo}
        idSucursal={idSucursal}
        idSistema={idSistema}
        idComproba={idComproba}
        nroReferencia={nroReferencia}
        cerrado={cerrado}
      />
    );
  };
  const tableRef = useRef();
  const estados = [{id: false, nombre: 'EN CURSO'}, {id: true, nombre:'PAUSADOS'},{id: undefined, nombre: 'TODOS'}]
  return (
    <Fragment>
      <TablaProcesosFiltros
        maquinas={maquinas}
        clientes={clientes}
        tipo={tipo}
        estados={estados}
        handleOnChangeFilters={onChangeFiltros}
        selectedOptions={filtros}
        key={nombre}
        procesos={procesos}
      />
      <div className={classes.root}>
        <div className={classes.table}>
          <Table
            tableRef={tableRef}
            title=""
            options={{
              tableLayout: "fixed",
            }}
            style={{
              width: "100%",
              borderRadius: "0px",
              boxShadow: "none",
            }}
            loading={false}
            columns={columns}
            data={data()}
            noBoxShadow
            customOptions={{
              thirdSortClick: false,
              sorting: true,
              grouping: false,
              draggable: false,
              search: false,
              paging: false,
              toolbar: false,
              showTitle: false,
              maxBodyHeight: 500,
              rowStyle: (rowData, index) => ({
                color: rowData.tableData.monto < 0 && "red",
                backgroundColor: !isEven(index) && "#f2f2f2",
              }),
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenDetalleDelTrabajoModal: (show, title, body) =>
      dispatch(actions.showEstadoModal(show, title, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaProcesos);
